import { Form, SubmissionError, useSelector } from 'dataformjs';
import { navigate } from 'gatsby';
import React, { FC } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import CircularProgress from '../../../components/base/CircularProgress';
import GridSplit from '../../../components/base/GridSplit';
import AsideBody from '../../../components/Space/Aside/Body';
import spaceQuery from '../../../objects/spaces/queries/space.graphql';
import vehicleData from '../../../objects/vehicles/datas.json';
import { vehiclesCreate as createMutation } from '../../../objects/vehicles/mutations';
import SpaceType from '../../../types/Space';
import pageProps, { ResultProps } from '../../../utils/pageProps';
import requiredAuth from '../../../utils/requireAuth';

const VehicleCreatePage: FC<
  ResultProps & {
    formName?: string;
  }
> = ({ formName = 'vehicle', params: { space: spaceId } }) => {
  const [createMutationFunc] = useMutation(createMutation);
  const { t } = useTranslation();
  const { data, error, loading } = useQuery(spaceQuery, {
    variables: {
      id: spaceId,
    },
  });

  const place = useSelector((state: any) =>
    state.form[formName] ? state.form[formName].values : {},
  );

  const handleOnSubmit = async (values: any) => {
    const {
      arrivalPlace,
      coefHour,
      coefKm,
      coefPec,
      coefVolume,
      coefWeight,
      departurePlace,
      hasHatch,
      hasPalletTruck,
      maxDuration,
      maxDistance,
      supDistance,
      supDuration,
      isActive,
      isRefrigerated,
      user,
      volume,
      weight,
      ...attributes
    } = values;

    if (!departurePlace || !departurePlace.id) {
      throw new SubmissionError({
        _error: 'vehicle.create.required.departurePlace',
      });
    }

    const result = await toast.promise(
      createMutationFunc({
        variables: {
          data: {
            ...attributes,
            spaceId,
            arrivalPlaceId: arrivalPlace?.id,
            coefHour: Number(coefHour) ? coefHour : null,
            coefKm: Number(coefKm) ? coefKm : null,
            coefPec: Number(coefPec) ? coefPec : null,
            coefVolume: Number(coefVolume) ? coefVolume : null,
            coefWeight: Number(coefWeight) ? coefWeight : null,
            departurePlaceId: departurePlace?.id,
            hasHatch: Boolean(hasHatch),
            hasPalletTruck: Boolean(hasPalletTruck),
            isActive: Boolean(isActive),
            isRefrigerated: Boolean(isRefrigerated),
            maxDistance: Number(maxDistance) ? maxDistance : null,
            maxDuration: Number(maxDuration) ? maxDuration : null,
            supDistance: Number(supDistance) ? supDistance : null,
            supDuration: Number(supDuration) ? supDuration : null,
            userId: user?.id,
            volume: Number(volume) ? volume : null,
            weight: Number(weight) ? weight : null,
          },
        },
      }),
      {
        error: 'Erreur lors de la création du véhicule',
        pending: 'Création en cours du véhicule',
        success: 'Création du véhicule réussi',
      },
    );

    if (!result || result.errors) {
      toast.error(result.errors);
    } else {
      const vehicleId = result.data.vehiclesCreate.id;
      window.history.replaceState(
        {
          spaceId,
          vehicleId,
        },
        '',
        `/admin/spaces/update/${spaceId}/?tab=3`,
      );
      navigate(`/admin/vehicles/update/${vehicleId}/`);
    }
  };

  if (loading) {
    return <CircularProgress fullPage />;
  }

  if (error) {
    return <pre>{JSON.stringify(error, null, 2)}</pre>;
  }

  const space: SpaceType = data && (data.space as SpaceType);

  if (!space) {
    return null;
  }

  return (
    <GridSplit
      aside={{
        body: place && <AsideBody places={[place]} zoom={15} />,
        className: 'sticky top-0 self-start',
        width: '40%',
      }}
      header={{
        back: {
          onClick: () => {
            window.history.back();
          },
          text: t(`back`),
        },
        title: `Création d'un véhicule`,
      }}
    >
      <Form
        className="mx-6 mb-6"
        datas={vehicleData}
        footerProps={{
          className: 'mt-12 flex justify-end',
        }}
        name={formName}
        onSubmit={handleOnSubmit}
        params={{
          spaceId,
        }}
        submitProps={{
          label: 'Créer ce véhicule',
        }}
      />
    </GridSplit>
  );
};

export default requiredAuth(pageProps(VehicleCreatePage), {
  admin: true,
  layout: true,
});
