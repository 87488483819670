import gql from 'graphql-tag';

const vehiclesCreate = gql`
  mutation vehiclesCreate($data: JSONObject!) {
    vehiclesCreate(data: $data) {
      id
    }
  }
`;

const vehiclesDelete = gql`
  mutation vehiclesDelete($id: GUID!) {
    vehiclesDelete(id: $id) {
      id
    }
  }
`;

const vehiclesSearch = gql`
  mutation vehiclesSearch($hitsPerPage: Int, $q: String!, $spaceId: GUID!) {
    vehiclesSearch(hitsPerPage: $hitsPerPage, q: $q, spaceId: $spaceId) {
      hits {
        id
        name
      }
    }
  }
`;

const vehiclesUpdate = gql`
  mutation vehiclesUpdate($data: JSONObject, $id: GUID!) {
    vehiclesUpdate(data: $data, id: $id) {
      id
    }
  }
`;

export { vehiclesCreate, vehiclesDelete, vehiclesSearch, vehiclesUpdate };
